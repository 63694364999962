.dashboard_container {
  padding: 0px 20px;
  display: flex;
  height: calc(100vh - 100px);
}

/* Mobile & tablet */
@media screen and (max-width: 1224px) {
  .dashboard_container {
    flex-direction: column;
    height: fit-content;
    padding: 0;
  }
}
