.container {
  width: 100%;
  height: 100vh;
  position: fixed;
}
.modal_background {
  background: rgba(85, 83, 83, 0.47);
  backdrop-filter: blur(5px);
  width: 100%;
  position: fixed;
  height: 100%;
}

.white_card {
  width: 30%;
  background: white;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 20px;
  height: fit-content;
}
.heading {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.placeholder_icon {
  color: var(--darkGray);
  margin-right: 10px;
  cursor: pointer;
}
.input_container {
  border: 2px solid var(--gray);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.input_container:hover {
  border: 2px solid var(--mainColor);
}
.input {
  outline: none;
  border: none;
  width: 100%;
  font-size: 1rem;
}
.input::placeholder {
  color: var(--darkGray);
  font-weight: 100;
  font-size: 0.71rem;
}
.cancel_btn,
.register_btn,
.register_btn_active {
  font-size: 0.875rem;
  background: var(--buttonGray);
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 7px;
  font-weight: bold;
}
.cancel_btn {
  margin-left: auto;
  margin-right: 10px;
}
.register_btn:hover,
.register_btn_active {
  background: black;
  color: white;
}
.cta_container {
  display: flex;
  margin-top: 40px;
  align-items: center;
}
.password_check_individual_container,
.password_check_individual_container_passed {
  display: flex;
  font-size: 0.71rem;
  align-items: center;
  color: red;
  margin-right: 10px;
}

.password_check_individual_container_passed {
  color: green;
}
.all_password_check_container {
  display: flex;
  margin-top: 5px;
}
.check_icon {
  margin-right: 2px;
  font-size: 0.875rem;
}
.loading_icon {
  margin-left: auto;
}

/*  tablet & mobile */
@media screen and (max-width: 1224px) {
  .white_card {
    width: 90%;
    height: fit-content;
  }

  .all_password_check_container {
    flex-direction: column;
  }
}
/*  landscape */
@media screen and (max-height: 540px) {
  .white_card {
    width: 90%;
    height: 80%;
    overflow: scroll;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
  }
}
