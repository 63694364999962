.container {
  display: flex;
  flex-direction: column;
}
.modal_background {
  background: rgba(85, 83, 83, 0.47);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
.preview_events_modal {
  position: absolute;
  background: white;
  width: 30%;
  height: fit-content;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}
.warning_icon {
  color: orange;
  font-size: 70px;
  text-align: center;
  margin: 0px auto 20px auto;
}
.heading {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}
.text {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}
.highlighted_word {
  font-weight: bold;
  text-align: center;
}
.cta_buttons_container {
  display: flex;
  margin-top: 20px;
}
.edit,
.proceed_to_payment {
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  background: var(--buttonGray);
}
.edit {
  margin-left: auto;
  margin-right: 10px;
}
.proceed_to_payment {
  background: black;
  color: white;
  margin-right: auto;
}
.flutterwave_credentials_input {
  border: 2px solid black;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 10px;
}
.flutterwave_credentials_input::placeholder {
  font-size: 10px;
}
.Prompt {
  margin: 20px 0px 10px 0px;
  font-weight: bold;
}
