.container {
  padding: 20px;
  display: flex;
  align-items: center;
  width: 100%;
}

.hamburger_button {
  margin-left: auto;
}

.nav_bar_container {
  display: flex;
  margin-left: 50px;
}

.auth_nav_bar_container {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.login_button {
  cursor: pointer;
  margin-right: 30px;
}
.register_button {
  cursor: pointer;
  background: black;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
}

.register_button:hover {
  background: rgba(0, 0, 0, 0.644);
}
.nav_items,
.nav_items_active {
  margin-right: 30px;
  cursor: pointer;
}

.nav_items_active {
  text-decoration: underline;
  font-weight: bold;
  color: var(--mainColor);
}
.nav_items:hover {
  text-decoration: underline;
}
.hamburger_nav_and_auth_container {
  display: flex;
  align-items: center;
  width: 100%;
}
.nav_and_auth_container {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
}
.logo {
  width: 10%;
  font-weight: bold;
}

/* Mobile & tablet */
@media screen and (max-width: 1224px) {
  .hamburger_nav_and_auth_container {
    display: block;
  }
  .nav_and_auth_container {
    z-index: 1;
    background: black;
    color: white;
    position: fixed;
    padding: 30px;
    top: 62px;
    left: 0;
    flex-direction: column;
    box-shadow: 0px 5px 15px -4px rgba(10, 10, 10, 0.71);
    -webkit-box-shadow: 0px 5px 15px -4px rgba(10, 10, 10, 0.71);
    -moz-box-shadow: 0px 5px 15px -4px rgba(10, 10, 10, 0.71);
  }
  .auth_nav_bar_container {
    flex-direction: column;
    margin-left: 0;
  }
  .nav_bar_container {
    flex-direction: column;
    margin-left: 0;
  }
  .login_button {
    margin-bottom: 30px;
    margin-right: 0px;
  }
  .nav_items,
  .nav_items_active {
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .nav_items_active {
    color: aqua;
  }
  .logo {
    width: 100%;
  }
  .register_button {
    color: black;
    background: white;
  }
}
