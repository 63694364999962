.side_pane_container {
  background: black;
  width: 20%;
  color: white;
  height: 100%;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.add_icon {
  border: 1px solid white;
  padding: 3px;
  border-radius: 3px;
  margin-right: 10px;
  height: 26px;
  width: 30px;
}
.add_event_container {
  margin-top: 30px;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  cursor: pointer;
}
.add_event_text {
  border: 1px solid white;
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 12px;
  width: 100%;
  text-transform: capitalize;
}
.active_indicator,
.inActiveIndicator {
  width: 5px;
  height: 13px;
  background: var(--mainColor);
  margin-right: 3px;
}
.inActiveIndicator {
  background: none;
}
.side_pane_icon {
  margin-right: 10px;
  /* font-size: 12px; */
}
/* .side_pane_text {
  font-size: 12px;
} */
.username {
  text-transform: capitalize;
}
.side_pane_nav_item_container,
.user_container {
  display: flex;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
}
.user_container {
  margin-top: auto;
  margin-bottom: 20px;
}
.kebab_icon {
  margin-left: auto;
}
.generate_ticket_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.page_heading {
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 0px;
}
.heading_description {
  margin-bottom: 30px;
  font-size: 12px;
  color: var(--darkGray);
}
.input {
  padding: 10px 0px;
  border-bottom: 1px solid black;
  background: white;
  width: 100%;
  font-size: 14px;
  outline: none;
}
.input:hover {
  border-bottom: 1px solid var(--mainColor);
}
.input_container,
.smaller_input_container,
.smallest_input_container {
  margin-right: 20px;
  margin-bottom: 40px;
  width: 45%;
}
.smaller_input_container {
  width: 20.5%;
}
.smallest_input_container {
  width: 13%;
}
.all_inputs_container {
  width: 45vw;
  display: flex;
  flex-wrap: wrap;
}
.label {
  font-size: 8px;
  font-weight: bold;
  color: var(--darkGray);
}

.generate_button_container {
  display: flex;
  margin-top: auto;
}

.generate_button,
.generate_button_inactive {
  background: black;
  width: fit-content;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 10px;
  color: white;
  margin-left: auto;
  margin-right: 40px;
  cursor: pointer;
}
.generate_button_inactive {
  background: var(--buttonGray);
}

.generate_button:hover {
  background: rgba(0, 0, 0, 0.644);
}
.loading_icon {
  margin-left: auto;
  margin-right: 40px;
}
.modal_background {
  background: rgba(85, 83, 83, 0.47);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
.scanning_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Mobile & tablet */
@media screen and (max-width: 1224px) {
  .side_pane_container {
    width: 95%;
    height: fit-content;
    padding: 20px;
    flex-direction: row;
    overflow: scroll;
    align-items: center;
    position: fixed;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .side_pane_text,
  .add_event_text,
  .username {
    display: none;
  }
  .add_event_container,
  .side_pane_nav_item_container,
  .user_container {
    margin: 0;
  }
  .add_event_container {
    margin-right: auto;
  }
  .user_container {
    margin-left: auto;
  }
  .add_icon {
    border: none;
  }

  .kebab_icon {
    margin-left: 0;
  }
  .side_pane_icon {
    margin-right: 15px;
  }
  .generate_ticket_container {
    margin-top: 70px;
    /* background: red; */
    height: calc(100vh - 140px);
    overflow: scroll;
  }
  .all_inputs_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .input_container,
  .smaller_input_container,
  .smallest_input_container {
    margin-right: 0px;
    margin-bottom: 40px;
    width: 100%;
  }
  .generate_button,
  .generate_button_inactive {
    margin-right: 0;
  }
}
