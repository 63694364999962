.container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.modal_background {
  background: rgba(85, 83, 83, 0.47);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
}

.white_card_and_preview_container {
  /* background: white; */
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.select_themes_container {
  width: 75%;
  height: fit-content;
  background: white;
  margin-right: 20px;
  border-radius: 10px;
  padding: 20px;
}
.preview_container {
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  width: 20%;
  background: white;
}
.page_heading {
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 40px;
}
.all_themes_container {
  display: flex;
  /* background: red; */
}
.image {
  width: 200px;
  height: 100px;
  object-fit: cover;
}
.theme_container,
.theme_container_selected {
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  margin-right: 30px;
  box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.theme_container_selected {
  outline: 2px solid var(--mainColor);
}
.title_and_price_container {
  display: flex;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  margin: 5px 0px;
}
.title {
  color: var(--mainColor);
}
.price {
  margin-left: auto;
}
.description {
  font-size: 10px;
  text-transform: lowercase;
}
.cta_buttons_container {
  display: flex;
  margin-top: 20px;
}
.cancel,
.generate_inactive,
.generate_active {
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  background: var(--buttonGray);
}
.cancel {
  margin-left: auto;
  margin-right: 10px;
}
.generate_active {
  background: black;
  color: white;
}
.preview_heading {
  text-transform: capitalize;
  font-weight: bold;
}
.loading_icon {
  margin-left: auto;
}
@media screen and (max-width: 1224px) {
  .white_card_and_preview_container {
    flex-direction: column;
  }
  .select_themes_container {
    width: 90%;
    padding: 10px;
  }
  .preview_container {
    display: none;
  }
  .page_heading {
    margin-bottom: 10px;
  }
  .theme_container,
  .theme_container_selected {
    width: 100%;
  }
}
