* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --mainColor: rgb(131, 32, 224);
  --gray: #d9d9d9;
  --darkGray: #908b8b;
  --buttonGray: #f5f5f5;
  --smallFont: 12px;
}
.generate_button,
.generate_button_inactive {
  background: black;
  width: fit-content;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 10px;
  color: white;
  margin-left: auto;
  margin-right: 40px;
  cursor: pointer;
}
.generate_button_inactive {
  background: var(--buttonGray);
}

.generate_button:hover {
  background: rgba(0, 0, 0, 0.644);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
